html,
body {
  max-width: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  padding: 0px;
  color: #333;
  background-color: #fff !important;
}

.conclusion {
  background-color: #33353a;
  color: #fff;
  margin-bottom: 0px;
  padding: 40px 0px;
}

.conclusion h1 {
  font-family: "GraphikMedium", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
}

.conclusion p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  margin-bottom: 30px;
}

.footer {
  margin-top: 80px;
  position: fixed;
  bottom: 0;
}

.footer-nav {
  display: flex;
  padding: 80px 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #33353a;
  background-color: #fff;
}

@media only screen and (max-width: 800px) {
  .footer-nav .container {
    width: 100%;
  }
  .footer-nav {
    padding: 50px 0px;
  }
}

.sections-menu-headline {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  line-height: 26px;
}

.structure-menu-link {
  display: block;
  margin-bottom: 22px;
  -webkit-transition: color 0.2s ease, -webkit-transform 0.4s ease;
  transition: color 0.2s ease, -webkit-transform 0.4s ease;
  transition: color 0.2s ease, transform 0.4s ease;
  transition: color 0.2s ease, transform 0.4s ease, -webkit-transform 0.4s ease;
  color: #8792a1;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.structure-menu-link:hover {
  color: #fff;
  text-decoration: none;
}

.copy {
  display: flex;
  height: 60px;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #33353a;
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.copy-inner {
  font-size: 14px;
  font-weight: 500;
  color: #33353a;
  background-color: #fff;
}

.secondary-btn {
  color: #fff;
  background-color: #0fb2b3;
  padding: 25px 70px;
  border: none;
  font-weight: 600;
  font-size: 22px;
}

.secondary-btn:hover,
.secondary-btn:active,
.secondary-btn:focus {
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
  transition: 0.2s all ease;
}

.navbar-default {
  background-color: transparent;
  z-index: 9;
  border: none;
}

.navbar-default .btn-primary {
  padding: 10px 40px;
  position: relative;
  bottom: 3px;
}

.navbar-nav > li a {
  padding-right: 0px;
  padding-left: 35px;
  font-size: 18px;
  font-weight: 300;
}

@media only screen and (max-width: 800px) {
  .navbar-nav > li a {
    padding-left: 5px;
  }
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-default .navbar-nav > li > a:hover {
  opacity: 0.7;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  color: #0fb2b3;
  background-color: #fff;
}

.navbar-brand {
  padding: 12px 0px;
}

.navbar-toggle {
  margin-right: 0px;
}

.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
  margin-left: 0px;
}

.btn-primary {
  padding: 15px 40px;
  border: none;
  font-weight: 700 !important;
  font-size: 22px;
  color: #fff;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-transform: none;
  background: #0fb2b3;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-weight: 100;
  width: 100%;
  font-size: 18px;
  margin-top: 20px;
  border-radius: 0px;
  text-align: center;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:disabled,
.btn-primary:focus {
  text-decoration: none;
  background-color: #0fb2b3 !important;
  opacity: 0.7;
}

h1 {
  font-weight: 800;
  font-size: 45px;
  line-height: 50px;
  color: #33353a;
  font-family: "GraphikMedium", sans-serif;
  margin: 10px 0px;
  font-weight: 300;
}

h4 {
  font-weight: 600;
  margin: 0px;
  font-size: 14px !important;
  font-size: 18px;
  line-height: 21px;
  color: #0b2e53;
}

h5 {
  font-size: 16px;
  line-height: 30px;
}

.container-fluid > .navbar-collapse,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container > .navbar-header {
  margin-right: 0px;
  margin-left: 0px;
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: none;
  padding: 20px 10px;
  font-size: 14px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 12px;
  padding: 5px;
  width: 30px !important;
}

input[type="number"],
input[type="text"],
input[type="password"],
input[type="email"] {
  min-height: 31px !important;
}

.react-datepicker-wrapper input[type="number"],
.react-datepicker-wrapper input[type="text"] {
  text-align: center;
}

.dateTitle {
  height: 31px;
  background: #fff;
  border: 1px solid #33353a;
  border-right: none;
  display: inline-block;
  width: 60px;
  text-align: center;
  padding: 4px;
  position: relative;
  bottom: 1px;
  font-size: 16px;
  font-weight: 300;
  background: #33353a;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 100%, 0.05)
  );
  color: #fff;
  border: none;
  margin-left: 15px;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  background: #fff !important;
}

.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
  background-color: #0fb2b3;
  border-color: #0fb2b3;
}

.fadedspan {
  color: rgb(165, 174, 176);
}

.fadedspan a {
  color: rgb(165, 174, 176);
  text-decoration: underline;
  font-size: 12px;
}
